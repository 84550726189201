import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { useEffect } from "react";

import "@birdiecare/design-system-css/src/styles/index.css";
import "~/styles/shared.css";
import { LoaderFunction, json } from "@remix-run/node";
import { TextLink } from "./components/TextLink";
import { Callout } from "./components/Callout";
import { assertEnvironment } from "~/utils/assertEnvironment";
import { DEFAULT_UNEXPECTED_ERROR_READABLE_MESSAGE } from "./utils/fhirV4";

export const loader: LoaderFunction = async ({ request }) =>
  json({
    ENV: {
      SENTRY_DSN: assertEnvironment("SENTRY_DSN"),
      NODE_ENV: assertEnvironment("NODE_ENV"),
      RELEASE: assertEnvironment("RELEASE"),
    },
  });

export const meta = () => [
  {
    charset: "utf-8",
  },
  { title: "GP Connect Record" },
  { viewport: "width=device-width,initial-scale=1" },
];

const AppWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <header className="appbar">
          <a href="/search">
            <h1 className="appbar__title">Birdie + GP Connect</h1>
          </a>
        </header>
        {children}
        <footer
          style={{
            marginBottom: "2rem",
            display: "grid",
            gridTemplateColumns: "1fr 10fr 1fr",
            padding: "1rem",
          }}
        >
          <span></span>
          <p>
            Your use of GP Connect is actively monitored by your Employer, and
            by Birdie. Users are reminded that to access, share or keep personal
            data without permission from your employer may be a criminal offence
            under Section 170 of the Data Protection Act 2018. This means that
            searching for records within GP Connect without a valid reason may
            lead to you personally being prosecuted, potentially resulting in an
            unlimited fine and a criminal record.
            <br />
            Birdie maintains audit logs of your actions on GP Connect, as a
            "Controller", in line with our{" "}
            <Link
              to="https://www.birdie.care/terms/privacy-notice"
              target="_blank"
              className="link"
            >
              Privacy Notice
            </Link>
            . For information about how your Employer processes your personal
            data, please refer to your employee privacy notice, or speak to your
            line manager.
          </p>
        </footer>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
};

const getErrorMessage = (error: any): string =>
  error?.humanReadableMessage ??
  error?.data?.humanReadableMessage ??
  DEFAULT_UNEXPECTED_ERROR_READABLE_MESSAGE;

/** Errors inside an ErrorBoundary are sanitised by Remix.
 * This means they are more human-readable and user-friendly
 * but it also means that the stack trace and other useful information is removed.
 *
 * So we do not report the error to Sentry at this point.
 * See https://remix.run/docs/en/main/guides/errors#error-sanitization
 */
export function ErrorBoundary() {
  const error = useRouteError() as Error;
  const message = getErrorMessage(error);

  return (
    <AppWrapper>
      <main className={"container"}>
        <section className="container__body">
          <div className={"styled"}>
            <TextLink>
              <Link to="/search">Back to search</Link>
            </TextLink>
            <Callout border={true}>
              <Callout.Icon variant="warning" />
              <Callout.Text testId="error-boundary-message">
                {message}
              </Callout.Text>
            </Callout>
            <p>
              An error occurred when using GP Connect. Please return to the
              patient record search and try again.
            </p>
          </div>
        </section>
      </main>
    </AppWrapper>
  );
}

function App() {
  const { ENV } = useLoaderData<typeof loader>();

  // Client-side Sentry initialisation
  if (ENV.SENTRY_DSN) {
    Sentry.init({
      dsn: ENV.SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches,
        }),
      ],
      environment: ENV.NODE_ENV,
      release: ENV.RELEASE,
    });
  }

  return (
    <AppWrapper>
      <Outlet />
    </AppWrapper>
  );
}

export default Sentry.withSentry(App);
